import { useContextMenu } from 'shared/hooks/useContextMenu';
import { createPortal } from 'react-dom';
import { useRef } from 'react';

import { Comments } from '../Comments/Comments';
import { Emotions } from '../Emotions/Emotions';
import styles from './Post.module.scss';
import { PostPropsI } from './Post.props';

export const Post = ({ text, emotions, image }: PostPropsI) => {
  const buttons = [
    { id: 1, name: 'Изменить', onClick: () => {} },
    { id: 2, name: 'Копировать текст', onClick: () => {} },
    { id: 3, name: 'Удалить', onClick: () => {} },
  ];

  const { handleContextMenu, isVisibleMenu, dropdownRef, menuPosition } = useContextMenu();

  return (
    <div onContextMenu={handleContextMenu} className={styles.container}>
      <div className={styles.images}>
        <img className={styles.image} height={336} alt="photo" src={image} />
      </div>

      <p className={styles.text}>{text}</p>

      <div className={styles.dop}>
        <Emotions emotions={emotions} className={styles.emotions} />{' '}
        <span className={styles.time}>15:34</span>
      </div>
      <Comments />
      {isVisibleMenu &&
        createPortal(
          <ul
            style={{ top: menuPosition.y, left: menuPosition.x }}
            className={styles.list}
            ref={dropdownRef}
          >
            {buttons.map(({ id, name, onClick }) => (
              <li key={id} className={styles.item}>
                <button onClick={onClick} className={styles.button}>
                  {name}
                </button>
              </li>
            ))}
          </ul>,
          document.body
        )}
    </div>
  );
};
