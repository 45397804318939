import { AddNewsBlock } from '../AddNewsBlock/AddNewsBlock';
import { NewsNavigations } from '../NewsNavigations/NewsNavigations';
import { Post } from '../Post/Post';
import styles from './NewsBlock.module.scss';
import firstMockImage from '../../../shared/assets/mock/firstMock.jpg';
import secondMockImage from '../../../shared/assets/mock/secondMock.jpg';

const mockData = [
  {
    text: `В главном морском аквариуме "Москвариума" поселился чернопятнистый скат. 
    "Чернопятнистый скат, Taeniura meyeni — первый представитель этого вида на экспозиции. В дикой природе эти хвостоколообразные скаты населяют тропические воды Индийского и Тихого океанов".
    Этот вид встречается как в прибрежной зоне, так и на глубинах до 500 метров.
    Чернопятнистые скаты питаются моллюсками и ракообразными, вырастают до внушительных размеров — почти до двух метров в диаметре и весом до 150 килограммов.`,
    emotions: { kiss: 7, smile: 20, heart: 34 },
    image: secondMockImage,
  },
  {
    text: `Манул Тимофей из Московского зоопарка начал готовиться к зажировке, у него порыжели бока, сообщили РИА Новости в пресс-службе зоосада.
Тимофей начал готовиться к зажировке, у него порыжели бока. Летом Тимоша худенький и темно-серый. К зиме он вдвое увеличивает свой вес, его мех становится более плотным и светло-серым с рыжими бочками.
В начале лета кот достиг своего минимального веса — 3,75 килограмма. Летом Тимофея кормили раз в день на протяжении шести дней в неделю. В конце августа — начале сентября зоологи стали постепенно увеличивать его рацион.`,
    emotions: { kiss: 17, smile: 20, heart: 7 },
    image: firstMockImage,
  },
];

export const NewsBlock = () => {
  return (
    <div className={styles.container}>
      <div>
        <AddNewsBlock />
        <div className={styles.posts}>
          {mockData.map(({ text, emotions, image }) => (
            <Post key={text} emotions={emotions} text={text} image={image} />
          ))}
        </div>
      </div>
      <NewsNavigations />
    </div>
  );
};
