import { Button } from 'shared/ui/Button';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';
import { UploadModal } from 'widgets/UploadModal';
import { useState } from 'react';
import { ReactComponent as EditSVG } from 'shared/assets/icons/edit.svg';
import { ChangePriceModal } from 'widgets/ChangePriceModal/ChangePriceModal';
import { Badge } from 'shared/ui/Badge';

import mockPhoto from '../../../shared/assets/mock/mock3.png';
import styles from './Hot.module.scss';
import { ReactComponent as AddIcon } from '../../../shared/assets/icons/addsquare.svg';

export const Hot = () => {
  const [isVisibleAddPhotoModal, setIsVisibleAddPhotoModal] = useState(false);
  const [isVisibleChangePriceModal, setIsVisibleChangePriceModal] = useState(false);
  const FakeData = [
    {
      img: mockPhoto,
      id: 0,
      price: 400,
    },
    {
      img: mockPhoto,
      id: 1,
      price: 400,
    },
    {
      img: mockPhoto,
      id: 2,
      price: 400,
    },
    {
      img: mockPhoto,
      id: 3,
      price: 400,
    },
  ];
  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <Button onClick={() => setIsVisibleAddPhotoModal(true)} type={6}>
          <AddIcon />
          Добавить
        </Button>
      </li>
      {/* {FakeData.map(({ img, id, price }) => (
        <li className={styles.item} key={id}>
          <img className={styles.image} src={img} alt="photos" />
          <div className={styles.controlls}>
            <button
              onClick={() => setIsVisibleChangePriceModal(true)}
              className={`${styles.button} ${styles.edit}`}
            >
              <EditSVG />
            </button>
            <button className={styles.button}>
              <DeletePhotoButton />
            </button>
          </div>
          <Badge additionalClasses={styles.badge} value={`${price}$`} />
        </li>
      ))} */}
      <UploadModal
        isVisible={isVisibleAddPhotoModal}
        close={() => setIsVisibleAddPhotoModal(false)}
        handleSetFiles={() => {}}
        dropzoneText="Загрузите фото с устройства или перетащите файлы в это поле"
        title="Добавить новое фото"
        isPutPrice
      />
      <ChangePriceModal
        isVisible={isVisibleChangePriceModal}
        close={() => setIsVisibleChangePriceModal(false)}
      />
    </ul>
  );
};
