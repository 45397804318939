import { ImagesSwitcher } from 'shared/ui/ImagesSwither';
import { useTimer } from 'shared/hooks/useTimer';
import { calculateRemainingTimePercents, getFormatedDate } from 'shared/helpers/dateFunctions';

import { AuctionCardPropsI, IObjectKeys } from './AuctionCard.props';
import styles from './AuctionCard.module.scss';
import { CommonBankWidget } from '../../../../widgets/Users/CommonBankWidget';
import { Transctions } from './components/Transctions';
import { BussinessButtons } from './components/BussinessButtons';

export const AuctionCard = ({
  initialBet,
  isAuctionsPage,
  isClosedTab,
  name,
  description,
  startDate,
  endDate,
  id,
  photos,
  fullRedemption,
}: AuctionCardPropsI) => {
  const DetailsMessageWithStatus = {
    default: 'Стартовая цена:',
    yellow: 'Аукцион закрыт',
    red: 'Аукцион закрыт (Победитель)',
  } as IObjectKeys;

  const operationsList = [
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, id: 1 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, id: 2 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, id: 3 },
  ];

  const { hours, minutes, seconds, isFinishTimer } = useTimer(endDate);

  const isTimerValueDontNull = hours && minutes && seconds;

  if (isFinishTimer && !isClosedTab) {
    return null;
  }

  return (
    // ${styles[status]} добавить ниже при необходимости
    <div className={`${styles.card} `}>
      <ImagesSwitcher isBuyed={false} width={256} height={256} images={photos} />
      <div className={styles.informations}>
        <p className={styles.title}>
          {name.ru}
          {/* {isNew && <Badge additionalClasses={styles.badge} type="lemon_chiffon" value="NEW" />} */}
        </p>
        <span className={styles.description}>{description.ru}</span>
        <div className={styles.timer}>
          {isAuctionsPage && (
            <div className={styles.members_info}>
              <span className={styles.members}>Учавствуют: 25</span>
              {isClosedTab && <span className={styles.winner}>Победитель: hdhjr_007</span>}
            </div>
          )}
          <div className={styles.header}>
            <p className={`${styles.timer_text} ${styles.text_color}`}>
              Стартовая цена: {''}
              {initialBet.toLocaleString('ru-RU')}
              {/* {status === 'red' && <CrownSVG className={styles.icon_crown} />} */}
            </p>
            {/* {status === 'default' && ( */}
            {isTimerValueDontNull && !isClosedTab && (
              <p className={`${styles.timer_text} ${styles.text_color}`}>
                {hours}ч {minutes}м {seconds}с
              </p>
            )}
            {isClosedTab && (
              <div className={styles.conducted}>
                <p>{getFormatedDate(String(startDate))}</p> -
                <p>{getFormatedDate(String(endDate))}</p>
              </div>
            )}
            {/* )}  */}
          </div>
          <span
            style={
              isClosedTab
                ? { width: '100%' }
                : { width: `${calculateRemainingTimePercents(endDate, startDate)}%` }
            }
            className={`${styles.progress_bar} ${styles.background}`}
          ></span>
        </div>
      </div>
      <div className={styles.price_container}>
        <CommonBankWidget price={initialBet} color={'default'} />
        {isAuctionsPage ? (
          <BussinessButtons
            images={photos}
            startDate={startDate}
            fullRedemption={fullRedemption}
            endDate={endDate}
            name={name.ru}
            initialBet={initialBet}
            description={description.ru}
            id={id}
            IsClosedTab={isClosedTab}
            className={styles.bussiness_buttons}
          />
        ) : (
          <Transctions className={styles.transactions} operationsList={operationsList} />
        )}
      </div>
    </div>
  );
};
