import { useAppDispatch } from 'app/store';
import { changeAuction, createAuction } from 'entities/auctions/api';
import { FileType } from 'shared/types/FileType';
import { FormType } from 'shared/types/FormType/FormType';
import { useEffect, useRef, useState } from 'react';
import { ToastVariants } from 'shared/context/ToastContext/interfaces';
import { AuctionPhoto } from 'entities/auctions/types';

import { useForm } from '../useForm/useForm';
import { useToast } from '../useToast';
import { instance } from '../../config/api/api';

export const useAuction = (
  name?: string,
  startDate?: string,
  endDate?: string,
  description?: string,
  initialBet?: number,
  images?: AuctionPhoto[],
  fullRedemption?: number,
  auctionId?: number
) => {
  const initialForm: FormType = {
    name: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Название',
      isRequired: true,
      value: name || '',
      errorText: '',
    },
    startDate: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Дата начала',
      isRequired: true,
      value: startDate || '',
    },
    endDate: {
      type: 'calendar',
      value: endDate || '',
      id: 10,
      isValid: false,
      placeholder: 'Дата окончания',
      isRequired: true,
    },
    bet: {
      type: 'input',
      id: 3,
      isValid: false,
      placeholder: 'Начальная ставка ($)',
      isRequired: true,
      value: initialBet || '',
      errorText: '',
    },
    redemption: {
      type: 'input',
      id: 3,
      isValid: false,
      placeholder: 'Сумма полного выкупа ($)',
      isRequired: true,
      value: fullRedemption || '',
      errorText: '',
    },
    publish: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Запланировать публикацию',
      isRequired: false,
      value: '',
    },
    description: {
      type: 'textarea',
      id: 4,
      placeholder: 'Описание аукциона',
      isRequired: true,
      isValid: false,
      errorText: '',
      value: description || '',
    },
  };

  const mappedImagesWithChangedAuction = images?.map(({ file, id, preview }) => {
    return {
      id: id,
      full: file,
      preview,
      type: 'image',
    };
  }) as FileType[];

  const [isVisibleUploadModal, setIsVisibleUploadModal] = useState(false);
  const [photos, setPhotos] = useState<FileType[]>(mappedImagesWithChangedAuction || []);
  let isCreatedOrChanged = useRef(false);
  const initialFiles = useRef<FileType[]>(photos);
  const newFiles = useRef<FileType[]>([]);

  const {
    form,
    handleChangeForm,
    isValidForm,
    getIsFormValid,
    handleResetFormInputs,
    isChangedForm,
  } = useForm(initialForm);
  const { showToast } = useToast();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const initialFilesFull = new Set(initialFiles.current.map((file) => file.full));
    const newFilesFull = new Set(newFiles.current.map((file) => file.full));

    photos.forEach((photo) => {
      if (!initialFilesFull.has(photo.full) && !newFilesFull.has(photo.full)) {
        newFiles.current.push(photo);
      }
    });
  }, [photos]);

  const cleanupFiles = () => {
    if (!isCreatedOrChanged.current && newFiles.current.length > 0) {
      instance
        .post('/delete-files', { files: newFiles.current })
        .catch((error) => console.error('Ошибка при удалении изображений', error));
    }
  };

  useEffect(() => {
    return cleanupFiles;
  }, []);

  const handleResetFullForm = () => {
    handleResetFormInputs();
    setPhotos([]);
  };

  const getRequestDate = () => ({
    name: form['name'].value,
    startDate: form['startDate'].value,
    endDate: form['endDate'].value,
    initialBet: form['bet'].value,
    description: form['description'].value,
    fullRedemption: form['redemption'].value,
    photos,
    id: auctionId,
  });

  const handleSetFiles = (files: FileType[]) => {
    setPhotos((prev) => [...prev, ...files]);
  };

  const handleChangeAuction = () => {
    isCreatedOrChanged.current = true;
    getIsFormValid();
    if (isValidForm) {
      dispatch(changeAuction(getRequestDate()));
      showToast({
        message: `Каталог успешно отредактирован, чтобы посмотреть перейдите на страницу Аукционы`,
        variant: ToastVariants.SUCCESS,
      });
    }
  };

  const handleCreateAuction = () => {
    isCreatedOrChanged.current = true;
    getIsFormValid();
    if (isValidForm) {
      dispatch(createAuction(getRequestDate())).then(() => {
        handleResetFullForm();
      });
      showToast({
        message: `Каталог успешно создан, чтобы посмотреть перейдите на страницу Аукционы`,
        variant: ToastVariants.SUCCESS,
      });
    }
  };

  const handleClickIsVisibleUploadModal = () => {
    setIsVisibleUploadModal(true);
  };

  return {
    handleChangeAuction,
    handleClickIsVisibleUploadModal,
    handleSetFiles,
    handleChangeForm,
    isVisibleUploadModal,
    isValidForm,
    setIsVisibleUploadModal,
    photos,
    form,
    setPhotos,
    handleCreateAuction,
    handleResetFullForm,
    isChangedForm,
  };
};
