import { createSlice } from '@reduxjs/toolkit';

import { changeAuction, closeAuction, createAuction, deleteAuction, getAllAuctions } from '../api';
import { AuctionsInitialStateI } from './interfaces';

const initialState: AuctionsInitialStateI = {
  auctions: [],
  auctionsLoading: false,
  auctionsError: null,
};
const auctionsSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all auctions
    builder.addCase(getAllAuctions.pending, (state) => {
      state.auctionsError = null;
      state.auctionsLoading = true;
    });
    builder.addCase(getAllAuctions.fulfilled, (state, action) => {
      state.auctions = action.payload.auctions;
      state.auctionsLoading = false;
      state.auctionsError = null;
    });
    builder.addCase(getAllAuctions.rejected, (state, action) => {
      state.auctions = [];
      state.auctionsLoading = false;
      state.auctionsError = action.payload as string;
    });
    // create auction
    builder.addCase(createAuction.pending, (state) => {});
    builder.addCase(createAuction.fulfilled, (state, action) => {});
    builder.addCase(createAuction.rejected, (state) => {});
    // close auction
    builder.addCase(closeAuction.pending, (state) => {});
    builder.addCase(closeAuction.fulfilled, (state, action) => {});
    builder.addCase(closeAuction.rejected, (state) => {});
    // delete auction
    builder.addCase(deleteAuction.pending, (state) => {});
    builder.addCase(deleteAuction.fulfilled, (state, action) => {});
    builder.addCase(deleteAuction.rejected, (state) => {});
    // change auction
    builder.addCase(changeAuction.pending, (state) => {});
    builder.addCase(changeAuction.fulfilled, (state, action) => {});
    builder.addCase(changeAuction.rejected, (state) => {});
  },
});

export const {} = auctionsSlice.actions;
export default auctionsSlice.reducer;
